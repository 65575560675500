import { appRoutes } from '@explorer/src';
import { Permissions } from '@explorer/stores';
import { insertIf } from '@explorer/helpers';

export const homeModules = (
  isSuperUser: boolean,
  isStaff: boolean,
  enableManageRequestFeature: boolean,
  featureFlagsLoading: boolean,
  enableZeroDayAlert: boolean,
): HomeModule[] => [
  {
    title: { id: 'home.monitoring-title' },
    description: { id: 'home.monitoring-description' },
    permissions: ['explorer:asm'],
    row: true,
    links: [
      {
        label: { id: 'home.monitoring-dashboard' },
        url: appRoutes['/asm'],
      },
      {
        label: { id: 'home.monitoring-companies' },
        url: appRoutes['/asm/companies'],
      },
      {
        label: { id: 'home.monitoring-notifications' },
        url: appRoutes['/asm/notifications'],
      },
      {
        label: { id: 'home.monitoring-assets' },
        url: appRoutes['/asm/assets'],
      },
      {
        label: { id: 'home.monitoring-vulnerabilities' },
        url: appRoutes['/asm/vulnerabilities'],
      },
      {
        label: { id: 'home.monitoring-technologies' },
        url: appRoutes['/asm/technologies'],
      },
    ],
  },
  {
    title: { id: 'home.admin-control-title' },
    description: { id: 'home.admin-control-description' },
    permissions: ['explorer:control', 'explorer:staff'],
    row: true,
    links: [
      {
        label: { id: 'admin.search-users' },
        url: appRoutes['/admin-control/users'],
      },
      {
        label: { id: 'admin.search-companies' },
        url: appRoutes['/admin-control/companies'],
      },
      {
        label: { id: 'admin.new-control-user' },
        url: appRoutes['/admin-control/new-control-user'],
      },
      {
        label: { id: 'admin.invite-users' },
        url: appRoutes['/admin-control/invite'],
      },
      {
        label: { id: 'admin.modify-client' },
        url: appRoutes['/admin-control/modify-client'],
      },
      {
        label: { id: 'admin.associate-email-to-client' },
        url: appRoutes['/admin-control/associate-email-to-client'],
      },
      ...insertIf(enableManageRequestFeature && !featureFlagsLoading, {
        label: { id: 'admin.manage-access-requests' },
        url: appRoutes['/admin-control/manage-access-requests'],
      }),
    ],
  },
  {
    title: { id: 'home.admin-explorer-title' },
    description: { id: 'home.admin-explorer-description' },
    permissions: ['explorer:superuser', 'explorer:staff'],
    hasAtLeastOnePermission: true,
    row: true,
    links: [
      ...insertIf(isStaff || isSuperUser, {
        label: { id: 'admin.search-companies' },
        url: appRoutes['/admin-explorer/companies'],
      }),
      /* If super user, concat these additional links to the explorer admin link module */
      ...insertIf(
        isSuperUser,
        {
          label: { id: 'admin.new-platform-user' },
          url: appRoutes['/admin-explorer/user'],
        },
        {
          label: { id: 'admin.add-existing-client-id' },
          url: appRoutes['/admin-explorer/client'],
        },
        {
          label: { id: 'admin.add-new-email-account' },
          url: appRoutes['/admin-explorer/email'],
        },
        {
          label: { id: 'admin.manage-tokens' },
          url: appRoutes['/admin-explorer/token'],
        },
      ),
    ],
  },
  {
    title: { id: 'home.supplementals-title' },
    description: { id: 'home.supplementals-description' },
    permissions: ['explorer:supplementals'],
    row: enableZeroDayAlert ? false : true,
    links: [
      {
        label: { id: 'home.supplementals-accounts' },
        url: appRoutes['/supplementals'],
      },
    ],
  },
  ...insertIf<HomeModule>(enableZeroDayAlert, {
    title: { id: 'home.zero-day-alert-title' },
    description: { id: 'home.zero-day-alert-description' },
    permissions: ['explorer:superuser', 'explorer:securityreview'],
    hasAtLeastOnePermission: true,
    links: [
      {
        label: { id: 'home.all-alerts' },
        url: appRoutes['/zero-day-alert'],
      },
    ],
  }),
  {
    title: { id: 'home.cra-title' },
    description: { id: 'home.cra-description' },
    permissions: ['explorer:cyberrisk'],
    links: [
      {
        label: { id: 'home.cra-reports' },
        url: appRoutes['/risk-assessment'],
      },
    ],
  },
  {
    title: { id: 'home.secreview-title' },
    description: { id: 'home.secreview-description' },
    permissions: ['explorer:securityreview'],
    links: [
      {
        label: { id: 'home.secreview-scans' },
        url: appRoutes['/sec-review'],
      },
      {
        label: { id: 'sec-review.asset-removals' },
        url: appRoutes['/sec-review/asset-removals'],
      },
      {
        label: { id: 'sec-review.vulnerability-resolutions' },
        url: appRoutes['/sec-review/vulnerability-resolutions'],
      },
    ],
  },
  {
    title: { id: 'home.execrisks-title' },
    description: { id: 'home.execrisks-description' },
    permissions: ['explorer:executiverisk'],
    links: [
      {
        label: { id: 'home.execrisks-scans' },
        url: appRoutes['/exec-risks'],
      },
    ],
  },
];

interface HomeModule {
  readonly title: IntlLabel;
  readonly description: IntlLabel;
  readonly permissions: Permissions[];
  readonly hasAtLeastOnePermission?: boolean;
  readonly row?: boolean;
  readonly links: Array<{
    readonly label: IntlLabel;
    readonly url: string;
  }>;
}
